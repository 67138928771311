import { useEffect, useMemo, useCallback, useState, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import {
  actions, Meeting, MeetingPollPriority, MeetingSlot, MeetingStatus, MeetingUpdate, RootState, ThunkDispatchType,
} from '../../store';
import PollResults, { ConvertedSlots, PollInfo } from './PollResults';
import { uniqBy } from 'lodash-es';
import { 
  EVENT_TYPE, GRANT_KEY_INT_TO_STRING, MEETING_STATUS_LABELS, PAGE_URL
} from '../../constants';
import { trackEventWithExtra } from '../../utils/appAnalyticsUtils';
import DuplicateMeetingModal, { 
  DuplicateMeetingSubmitProps 
} from '../../components/Schedule/DuplicateMeetingModal/DuplicateMeetingModal';
import ScheduleShareModalContainer from "../../components/Schedule/ScheduleShareModal";
import { useMountEffect } from '../../utils/hooks';
import DeleteMeetingModal from '../../components/Schedule/DeleteMeetingModal';
import { allTimeZones, getLocalTimeZone, removeTimesFromSlot } from '../../utils/scheduleUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  selectMeetingBookingSlots, selectMeetingSlots, selectLocations, selectParticipantsWithSelections
} from '../../store/schedule/selectors';

type RouteParams = { pollIdStr: string; };


const PollResultsContainer = (): ReactElement | null => {
  const params = useParams<RouteParams>();
  const pollId = params.pollIdStr ? Number(params.pollIdStr) : undefined;

  const grants = useSelector((state: RootState) => state.auth.user?.oauth_grant_details);
  const meetings = useSelector((state: RootState) => state.schedule.meetings);
  const user = useSelector((state: RootState) => state.auth.user);
  const meetingSlots = useSelector((state: RootState) => selectMeetingSlots(state, pollId));
  const questionAnswers = useSelector((state: RootState) => state.schedule.questionAnswers);
  const calendarsLoaded = useSelector((state: RootState) => state.schedule.calendarsLoaded);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const defaultCalendarTimezone = useSelector((state: RootState) => 
    state.schedule.schedulingPrefs.user_prefs?.default_calendar_timezone);

  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();

  const fetchMeetingSlots = useCallback((unscheduled: boolean) => 
    dispatch(actions.schedule.fetchMeetingSlots(unscheduled)), [dispatch]);
  const fetchMultipleExternalMeetingAnswers = useCallback((ids: number[]) => 
    dispatch(actions.schedule.fetchMultipleExternalMeetingAnswers(ids)), [dispatch]);
  const updateMeeting = useCallback((
    meeting: MeetingUpdate, timeSlots: MeetingSlot[], files?: File[] | undefined
  ) => dispatch( actions.schedule.updateMeeting(meeting, timeSlots, files)), [dispatch]);
  const duplicateMeeting = (meetingId: number, data: Partial<Meeting>) => dispatch(actions.schedule.duplicateMeeting(
    meetingId, data
  ));
  const deleteMeeting = (meeting: Meeting) => dispatch(actions.schedule.deleteMeeting(meeting));

  const [loading, setLoading] = useState(true);
  const [showDuplicateMeeting, setShowDuplicateMeeting] = useState(false);
  const [showDeleteMeeting, setShowDeleteMeeting] = useState(false);
  const [scheduleShareMeetingId, setScheduleShareMeetingId] = useState(-1);

  const poll = pollId && meetings[pollId] && meetings[pollId].is_poll ? meetings[pollId] : null;
  const locations = useSelector((state: RootState) => selectLocations(state, pollId));
  const participants = useSelector((state: RootState) => selectParticipantsWithSelections(state, pollId));
  const {
    booking_slots: bookingSlots, conflict_slots: conflictSlots,
  } = useSelector((state: RootState) => selectMeetingBookingSlots(state, pollId));

  const timezone = useMemo(() => (defaultCalendarTimezone
    ? allTimeZones.find((zone) => zone.name === defaultCalendarTimezone)
    : getLocalTimeZone()
  ), [defaultCalendarTimezone]);

  const pollCalendar = useMemo(() => calendars.find(
    cal => cal.id === poll?.booking_calendar
  ), [calendars, poll?.booking_calendar]);

  const hasGrant = useMemo(() => {
    if (grants && pollCalendar?.provider) {
      const grantKey: string = GRANT_KEY_INT_TO_STRING[pollCalendar.provider];
      return !!grants[grantKey];
    }
    return false;
  }, [grants, pollCalendar?.provider]);

  const handleEdit = (id: number) => {
    navigate(`${PAGE_URL.SCHEDULE}/${id}/`);
  };

  const handleDuplicate = () => {
    setShowDuplicateMeeting(true);
  };

  const handleDeletePoll = () => {
    setShowDeleteMeeting(true);
  };

  const onCloseShareModal = () => {
    setScheduleShareMeetingId(-1);
  };

  const handleOpenShareMeetingModal = () => {
    if (pollResults) {
      setScheduleShareMeetingId(pollResults.id);
    }
  };


  const submitDuplicate = async (data: DuplicateMeetingSubmitProps) => {
    if (pollId) {
      const meeting = await duplicateMeeting(pollId, data);
      if (meeting) {
        setShowDuplicateMeeting(false);
        setScheduleShareMeetingId(meeting.id);
        return meeting;
      }
    }
    return undefined;
  };

  const submitDeletePoll = async (meeting: Meeting) => {
    await deleteMeeting(meeting);
    navigate(PAGE_URL.POLL_RESULTS);
    setShowDeleteMeeting(false);
  };

  const handleUpdateStatus = async (id: number, status: number) => {
    const date_scheduled = status !== MeetingStatus.PENDING ? DateTime.utc().toString() : null;
    await updateMeeting({ id, date_scheduled, status }, []);
    trackEventWithExtra({
      eventName: EVENT_TYPE.SCHEDULING_POLL_CHANGE_STATUS,
      extra: { status: MEETING_STATUS_LABELS[status] },
    });
  };

  const pollResults: PollInfo | null = useMemo(() => {
    if (!poll) return null;

    const votedSlots = uniqBy(Object.values(poll.poll_selections || {}), selection => (
      `${selection.start_date}-${selection.end_date}`
    )).map(s => ({ start: s.start_date, end: s.end_date, priority: MeetingPollPriority.AVAILABLE })) || [];

    const allSlots = uniqBy([
      ...bookingSlots.map(s => ({ ...s, priority: MeetingPollPriority.AVAILABLE })),
      ...conflictSlots.map(s => ({ ...s, priority: MeetingPollPriority.AVAILABLE })),
      ...votedSlots.map((s, i) => ({...s, id: i * -1, meeting: poll.id})),
    ], s => s.start).map(slot => ({
      id: slot.id,
      start: DateTime.fromISO(slot.start),
      end: DateTime.fromISO(slot.end),
      priority: slot.priority,
    })).sort((a, b) => a.start.toMillis() - b.start.toMillis());

    return {
      id: poll.id,
      from: poll.create_user,
      name: poll.status === MeetingStatus.SCHEDULED
      && poll.title_booked ? poll.title_booked : poll.title,
      description: poll.status === MeetingStatus.SCHEDULED
      && poll.description_booked ? poll.description_booked : poll.description || '',
      durationMinutes: poll.duration_minutes || 0,
      expectedParticipants: poll.num_expected_participants || 0,
      slots: allSlots,
      participants: participants,
      startTime: poll.event_start_time,
      status: poll.status,
      questions: poll.questions,
      locations: locations,
      calendarName: poll.calendar_info?.calendar_name
    };
  }, [poll, locations, participants, bookingSlots, conflictSlots]);

  const handleExport = (timeslotSegments: ConvertedSlots[]) => {
    const title = ["Meeting name: ", poll?.title || ""];
    const blank = [""];
    const data = [title, blank, blank];

    timeslotSegments.forEach(convertedSlots => {
      const sortedSlots = convertedSlots.sort((a, b) => (a.start > b.start) ? 1 : -1);

      const headerDates = ["", ""];
      sortedSlots?.forEach(slot => (
        headerDates.push(`${slot.start.toLocaleString({ month: "numeric", day: "numeric" })}`)
      ));

      const headerTimes = ["", ""];
      sortedSlots?.forEach(slot => (
        headerTimes.push(`${slot.start.toLocaleString({ hour: 'numeric', hour12: true, minute: 'numeric', })
          .toLowerCase().split(' ').join('')}` +
          ` - ${slot.end.toLocaleString({ hour: 'numeric', hour12: true, minute: 'numeric' })
            .toLowerCase().split(' ').join('')}`)
      ));

      data.push(headerDates, headerTimes);

      pollResults?.participants.forEach(participant => {
        const newParticipant = [];
        const participantStartTimes = participant.selectedSlots.map(selectedSlot => {
          return selectedSlot.start.toSeconds();
        });
        newParticipant.push(participant.name);
        newParticipant.push(participant.email || "");
        sortedSlots?.forEach(slot => {
          if (participantStartTimes.includes(slot.start.toSeconds())) {
            const currentSlot = participant.selectedSlots
              .find(timeSlot => timeSlot.start.toSeconds() === slot.start.toSeconds());
            if (currentSlot?.priority === 1) {
              newParticipant.push("Available");
            } else {
              newParticipant.push("Potentially");
            }
          } else {
            newParticipant.push("");
          }
        });
        data.push(newParticipant);
      });

      data.push(blank, blank);
    });

    data.push(blank);

    if (pollResults?.questions) {
      Object.values(pollResults?.questions)?.forEach(question => {
        data.push(["Poll Question:", "", question.title]);
        if (question.answers) {
          Object.values(question.answers)?.forEach(answer => {
            const participant = pollResults?.participants.find(
              participantItr => participantItr.id === answer.participant
            );
            data.push([
              participant?.name || "",
              participant?.email || "",
              answer.text || "",
            ]);
          });
        }
        data.push(blank);
      });
    }

    return data;
  };

  const handleRemoveTimes = async (
    timeSlotsToRemove: { start: DateTime; end: DateTime }[],
    timeSlotsToKeep: { start: DateTime; end: DateTime }[],
  ) => {
    const slotsToUpdate: { slotId: number; newSlots: { start: DateTime; end: DateTime }[] }[] = [];

    meetingSlots.forEach(meetingSlot => {
      const slot = { start: DateTime.fromISO(meetingSlot.start_date), end: DateTime.fromISO(meetingSlot.end_date) };
      const newSlots = removeTimesFromSlot(slot, timeSlotsToRemove, timeSlotsToKeep);

      // skip unchanged slots
      if (newSlots.length === 1
        && newSlots[0].start.toMillis() === slot.start.toMillis()
        && newSlots[0].end.toMillis() === slot.end.toMillis()
      ) {
        return;
      }

      slotsToUpdate.push({ slotId: meetingSlot.id, newSlots });
    });

    await dispatch(actions.schedule.batchDeleteTimeSlots(slotsToUpdate.map(s => s.slotId)));
    await dispatch(actions.schedule.batchCreateTimeSlots(slotsToUpdate.flatMap(s => s.newSlots.map(ss => ({
      id: -1,
      meeting: poll?.id || -1,
      start_date: ss.start.toISO() || "",
      end_date: ss.end.toISO() || "",
      is_exclude: false,
    })))));
  };

  const initialFetches = useCallback(async () => {
    dispatch(actions.schedule.fetchZoomSettings());
    dispatch(actions.schedule.fetchMeetingRooms());
    dispatch(actions.schedule.fetchPresetLocations());
    dispatch(actions.schedule.fetchSchedulingPreferences());

    if (pollId) {
      await dispatch(actions.schedule.fetchMeeting(pollId.toString()));
      if (!calendarsLoaded) {
        dispatch(actions.schedule.fetchRemoteCalendars());
      }
    }
  }, [calendarsLoaded, pollId, dispatch]);

  useMountEffect(() => {
    initialFetches();
  });

  useEffect(() => {
    if (poll?.id) {
      fetchMultipleExternalMeetingAnswers([poll.id]);
    }
  }, [poll?.id, fetchMultipleExternalMeetingAnswers]);

  useEffect(() => {
    if (poll?.external_id) {
      dispatch(actions.schedule.fetchMeetingExternal(poll.external_id, undefined, DateTime.now()));
    }
  }, [poll?.external_id, dispatch]);

  useEffect(() => {
    if (poll?.status) {
      setLoading(true);
      fetchMeetingSlots(poll?.status === MeetingStatus.PENDING).then(() => setLoading(false));
    }
  }, [fetchMeetingSlots, poll?.status]);

  if (!pollResults || !poll) return null;

  return (
    <>
      <PollResults
        inAdmin={true}
        poll={pollResults}
        // if by setting onConvertToMeeting to undefined
        // ui to book the meeting will be blocked
        hasGrant={hasGrant}
        onEdit={handleEdit}
        handleDuplicate={handleDuplicate}
        handleDeletePoll={handleDeletePoll}
        onExport={handleExport}
        onUpdateStatus={handleUpdateStatus}
        loading={loading}
        pollId={pollId}
        meetingSlots={meetingSlots}
        questionAnswers={questionAnswers}
        showSplitByDay={user?.features.POLL_RESULTS_DAY_SPLIT}
        onOpenShareMeetingModal={handleOpenShareMeetingModal}
        timezone={timezone}
        onRemoveTimes={handleRemoveTimes}
      />
      <DuplicateMeetingModal
        meeting={poll}
        onClose={() => setShowDuplicateMeeting(false)}
        open={showDuplicateMeeting}
        submitDuplicate={submitDuplicate}
      />
      <DeleteMeetingModal
        open={showDeleteMeeting}
        onClose={() => setShowDeleteMeeting(false)}
        meeting={poll}
        submitDelete={submitDeletePoll}
      />
      {scheduleShareMeetingId &&
        <ScheduleShareModalContainer
          meetingId={scheduleShareMeetingId}
          onModalClose={onCloseShareModal}
          modalOpen={scheduleShareMeetingId > 0}
          showEdit
        />
      }
    </>
  );
};

export default PollResultsContainer;
